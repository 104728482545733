import React from 'react'
import Logo from '../../images/co-logo-white.svg'
import TwitterIcon from '../../images/icon-twitter.svg'
import GithubIcon from '../../images/icon-github.svg'
import MailIcon from '../../images/icon-mail.svg'

import './Header.scss'

const Header = ({ siteTitle }) => (
  <div className="header__root">
    <div className="callout">
      <div className="left">
        <p>I do freelancing!</p>
      </div>
      <div className="right">
        <a
          href="https://chaseohlson.com"
          target="blank"
          className="btn__outline"
        >
          Hire Me
        </a>
      </div>
    </div>
    <div className="links">
      <div className="icon">
        <img src={Logo} alt="Logo" />
      </div>
      <div className="social">
        <a href="https://twitter.com/ChaseOhlsonWeb" target="blank">
          <img src={TwitterIcon} alt="Twitter" />
        </a>
        <a href="https://github.com/brohlson" target="blank">
          <img src={GithubIcon} alt="Github" />
        </a>
        <a href="mailto:chase@chaseohlson.com" target="blank">
          <img src={MailIcon} alt="Mail" />
        </a>
      </div>
    </div>
  </div>
)

export default Header
